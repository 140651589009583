/* Run functions on document.ready */
$(document).ready(function(){
	/* Main navigation */
    $('.main-nav li').has('ul.sub').hover(function(){
        $(this).addClass('hover');
        $('ul:first', this).css('display', 'block');
    }, function(){
        $(this).removeClass('hover');
        $('ul:first', this).css('display', 'none');
    });

    /* Format tables and lists */
    var mainDiv = $('div.main-col');
    if (mainDiv.length){
        // Convert tables to uikit style table
        mainDiv.find('table').not('.exception').each(function(){
            var table = $(this);
            table.wrap('<div class="uk-overflow-container" />');
            //table.addClass('uk-table uk-table-striped');
        });
    }
});

/* Run functions on window.load */
$(window).load(function(){

});
